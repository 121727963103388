<template>
    <AdminWrapper>
        <template v-slot:child>
            <!-- Partners Users Profile -->
            <template v-if="$store.state.Admin.loadingState"><Spinner /></template>

            <div class="w-90 center pt5">
                <div class="flex scrollmenu">
                    <div :class="tabView === 1 && 'active-menu'" class="mr4 makeCursorPointer" @click="toggleTab(1)">
                        Partners
                    </div>
                    <div :class="tabView === 2 && 'active-menu'" class="mr4 makeCursorPointer" @click="toggleTab(2)">Users</div>
                    <div :class="tabView === 3 && 'active-menu'" class="mr4 makeCursorPointer" @click="toggleTab(3)">Business Invites</div>
                    <div :class="tabView === 4 && 'active-menu'" class="mr4 makeCursorPointer" @click="toggleTab(4)">Profile</div>
                </div>
            </div>

            <!-- All Partners -->
            <div v-show="tabView === 1">
                <partners />
            </div>
            
            <!-- All Users -->
            <div v-show="tabView === 2">
                <users />
            </div>
            
            <!-- All business invites -->
            <div v-show="tabView === 3">
                <business-invites />
            </div>

            <!-- Profile -->
            <div v-show="tabView === 4">
                <div class="flex flex-wrap items-center justify-between items-center pv4 w-90 center">
                    <div>
                        <div class="pb3 pb0-l f5 b lh-copy">Business Profile</div>
                    </div>
                    <div>
                        <button class="btn3 mr3">Create users</button>
                        <button class="btn3">Deactive</button>
                    </div>
                </div>

                <div class="w-90 center flex-l items-start justify-between">
                    <div class="box-border pa3 w-50-l w-100 mb4 mb0-l">
                        <div class="flex flex-column">
                            <div class="b pt4 pb2">First Name</div>
                            <input type="text" class="pa2 box-border" name="" placeholder="Donye Collins" id="" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Last Name</div>
                            <input type="text" class="pa2 box-border" name="" placeholder="Donye Collins" id="" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Email</div>
                            <input type="email" class="pa2 box-border" name="" placeholder="Donye@hot.co" id="" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Phone No</div>
                            <input type="number" class="pa2 box-border" name="" placeholder="07055896214" id="" />
                        </div>
                        <div class="flex flex-column">
                            <div class="b pt3 pb2">Role</div>
                            <input type="text" class="pa2 box-border" name="" placeholder="Admin" id="" />
                        </div>
                        <div class="flex flex-column pb4">
                            <div class="b pt3 pb2">Role description</div>
                            <input
                                type="text"
                                class="pa2 box-border"
                                name=""
                                placeholder="Importing and exporting of  staff to uk for faji"
                                id=""
                            />
                        </div>
                        <div class="tr pv2">
                            <button class="btn1">Update Profile</button>
                        </div>
                    </div>
                    <div class="box-border pa3 w-50-l w-100 ml4-l ">
                        <div class="box-border-bottom">
                            <h3>Profile photo</h3>
                        </div>

                        <div class="flex pv4">
                            <img :src="require('@/assets/images/profile-pic.svg')" alt="" />
                            <div class="pl3">
                                <div class="font-w2 pb2">Edit your photo</div>
                                <div class="flex">
                                    <a href="">Delete</a>
                                    <a href="" class="ml3 font-w2" style="color:#132C8C;">Update</a>
                                </div>
                            </div>
                        </div>
                        <label for="file-upload" class="custom-file-upload w-100 bw1 dashedinputFile" style="text-align:center">
                            <img :src="require('@/assets/images/images.svg')" class="pb3 pt4" alt="" />
                            <div class="font-w2 pb2">
                                Drag and drop an image, or <span href="" style="color:#132C8C;">Browse</span>
                            </div>
                            <div class="font-w1 pb3">386 * 250 PX or higher recommended, Max 3MB (webp, png, jpg)</div>
                            <input id="file-upload" type="file" accept=".webp, .png, .jpg, .jpeg" style="visibility: hidden" />
                        </label>
                    </div>
                </div>
            </div>
        </template>
    </AdminWrapper>
</template>

<script>
import { reactive, ref } from '@vue/reactivity'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import Partners from '@/views/Admin/Setting/Partners'
import Users from '@/views/Admin/Setting/Users'
import BusinessInvites from '@/views/Admin/Setting/BusinessInvites'
import Pagination from '@/components/Pagination'
import Spinner from '@/components/Spinner'
export default {
    components: {
        AdminWrapper,
        Partners,
        Users,
        BusinessInvites,
        Pagination,
        Spinner
    },
    setup() {
        const tabView = ref(1)
        const store = useStore()
        const route = useRoute()
        const query = ref({
            limit: route.query.limit || 10,
            page: route.query.page || 1
        })
        const partners = computed(() => store.state.Admin.partners)

        const toggleTab = num => {
            tabView.value = num
        }

        const onNavigate = query => {
            store.dispatch('Admin/getPartners', query)
        }

        onMounted(() => {
            store.dispatch('Admin/getPartners', {
                limit: route.query.limit || 10,
                page: route.query.page || 1
            })
        })

        return { tabView, toggleTab, query, partners, onNavigate }
    }
}
</script>

<style scoped>
.makeCursorPointer {
    cursor: pointer;
}

.dGrid {
    display: grid;
}

.cardwidth {
    width: 30rem;
}

.gridCol3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media screen and (max-width: 750px) {
    .gridCol3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .cardwidth {
        width: 100%;
    }
}
</style>
