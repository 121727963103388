<template>
    <!-- All Partners -->
    <div>
        <!-- Partners -->
        <div class="flex flex-wrap items-center justify-between items-center pt4 w-90 center">
            <div>
                <div class="pb3 pb0-l f5 b lh-copy">Partners</div>
            </div>
            <div>
                <router-link :to="{ name: 'AdminSettingCreatePartner' }"
                    ><button class="btn3">Create Partners</button></router-link
                >
            </div>
        </div>

        <!-- All partners -->
        <div class="w-90 center pv4">
            <div class="font-w2" style="color:#132C8C;">All Partners</div>
        </div>

        <!-- cards  -->

        <div class="w-90 center dGrid gridCol3">
            <div v-for="partner in partners" :key="partner._id" class="cardwidth pa4 mr3-l mb0-l mt1 mb4 box-border">
                <img :src="require('@/assets/images/Shopee_Food_Logo.svg')" alt="" />
                <h3>{{ partner.name }}</h3>
                <h3>Details</h3>
                <p class="lh-copy">{{ partner.description }}</p>
                <div class="flex justify-between">
                    <div>
                        <h3>Customer Discount</h3>
                        <div>{{ partner.customerDiscount }}</div>
                    </div>
                    <div>
                        <h3>Duration</h3>
                        <div>{{ partner.duration }}</div>
                    </div>
                    <div>
                        <h3>Partner Discount</h3>
                        <div>{{ partner.partnerDiscount }}</div>
                    </div>
                </div>
                <div class="pt4 font-w2">
                    <router-link :to="{ name: 'AdminSettingPartnerDetails', params: { id: partner?._id } }"
                        >Explore details -></router-link
                    >
                </div>
            </div>
        </div>

        <Pagination
            class="mt3"
            :limit="$route.query.limit"
            :pages="partners?.pagination"
            route-name="Admin Setting"
            :onNavigate="onNavigate"
        />
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import Pagination from '@/components/Pagination'
import Spinner from '@/components/Spinner'
export default {
    components: {
        AdminWrapper,
        Pagination,
        Spinner
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const query = ref({
            limit: route.query.limit || 10,
            page: route.query.page || 1
        })
        const partners = computed(() => store.state.Admin.partners)

        const onNavigate = query => {
            store.dispatch('Admin/getPartners', query)
        }

        onMounted(() => {
            store.dispatch('Admin/getPartners', {
                limit: route.query.limit || 10,
                page: route.query.page || 1
            })
        })

        return { query, partners, onNavigate }
    }
}
</script>

<style scoped>
.makeCursorPointer {
    cursor: pointer;
}

.dGrid {
    display: grid;
}

.cardwidth {
    width: 30rem;
}

.gridCol3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media screen and (max-width: 750px) {
    .gridCol3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .cardwidth {
        width: 100%;
    }
}
</style>
