<template>
    <div>
        <div class="flex flex-wrap items-center justify-between items-center pv4 w-90 center">
            <div>
                <div class="pb3 pb0-l f5 b lh-copy">Users</div>
            </div>
            <div>
                <button class="btn3 mr3 mb0-l mb3">Create users</button>
                <button class="btn3 mr3 mb0-l mb3">Invite Someone</button>
                <button class="btn3 mb0-l mb3">Manage Roles</button>
            </div>
        </div>

        <div class="scrollmenu w-90 center pt2">
            <table class="w-100 tl pt3 bt b--black-20" cellspacing="0">
                <thead>
                    <tr>
                        <th class="pb3 bb b--black-20 pr3 tl lh-title">Name</th>
                        <th class="pb3 bb b--black-20 pr3 tl lh-title">Email address</th>
                        <th class="pb3 bb b--black-20 pr3 tl lh-title">Created</th>
                        <!-- <th class="pb3 bb b--black-20 pr3 tl lh-title">Action</th> -->
                        <!-- <th class="pb3 bb b--black-10 pr3 tl lh-title"></th> -->
                    </tr>
                </thead>
                <tbody v-if="users.length">
                    <tr v-for="user in users" :key="user._id" class="font-w1">
                        <td class="pv3 pr3 bb b--black-20 tl">{{ user.firstName }} {{ user.lastName }}</td>
                        <td class="pv3 pr3 bb b--black-20 tl">{{ user.email }}</td>
                        <td class="pv3 pr3 bb b--black-20 tl">{{ formatDate(user.createdAt) }}</td>
                        <!-- <td class="pv3 pr3 bb b--black-20 tl">Change role</td> -->
                        <!-- <td class="pv3 pr3 bb b--black-10 tl">Remove</td> -->
                    </tr>
                </tbody>
                <tbody v-else>
                    <tr>
                        <td colspan="5">
                            <TableEmptyState 
                                :data-array="invites" 
                                message="No business users record" 
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-if="users.length">
            <Pagination
                class="mt3"
                :limit="$route.query.limit"
                :pages="users?.pagination"
                route-name="Admin Setting"
                :onNavigate="onNavigate"
            />
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity'
import AdminWrapper from '../../../layout/AdminWrapper.vue'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import Pagination from '@/components/Pagination'
import TableEmptyState from '@/components/TableEmptyState'

import Spinner from '@/components/Spinner'
import { formatDate, formatDateFilter } from '@/utils/lib'
export default {
    components: {
        AdminWrapper,
        Pagination,
        Spinner,
        TableEmptyState
    },
    setup() {
        const store = useStore()
        const route = useRoute()
        const start = ref(formatDateFilter(new Date(new Date().setFullYear(new Date().getFullYear() - 23))))
        const end = ref(formatDateFilter(new Date()))
        const query = ref({
            start: start.value,
            end: end.value,
            limit: route.query.limit || 10,
            page: route.query.page || 1
        })
        const users = computed(() => store.state.Admin.businessesUsers)

        const onNavigate = query => {
            store.dispatch('Admin/getBusinessesUsers', query)
        }

        onMounted(() => {
            store.dispatch('Admin/getBusinessesUsers', {
                filterType: 'adminRange',
                start: '2020-01-01',
                end: formatDateFilter(new Date()),
                limit: route.query.limit || 10,
                page: route.query.page || 1
            })
        })

        return {
            query,
            users,
            onNavigate,
            formatDate,
        }
    }
}
</script>

<style scoped>
.makeCursorPointer {
    cursor: pointer;
}

.dGrid {
    display: grid;
}

.cardwidth {
    width: 30rem;
}

.gridCol3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

@media screen and (max-width: 750px) {
    .gridCol3 {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .cardwidth {
        width: 100%;
    }
}
</style>
