<template>
    <div v-if="showUserBulkInviteModal">
        <div class="jquery-modal blocker current" @click="closeModalOnOutsideClick" ref="importRef">
            <div id="modal-bulk" class="modal-target2">
                <div class="w-100">
                    <div class="w-100 box-border pa3" style="background: white">
                        <h3>User Bulk Invite</h3>
                        <div class="lh-copy">
                            Download the users invite <a href="" @click.prevent="downloadUsersInviteTemplate" class="b text-info">template</a>, update and upload the edited CSV file.
                        </div>
                        <form class="flex flex-column mv3" @submit.prevent="onSubmit">
                            <input
                                type="file"
                                name="imageFile"
                                id="file"
                                class="product-input"
                                @change="onFileChange"
                                accept="text/csv"
                                required
                            />
                            <button :disabled="state.buttonDisabled">
                                {{ state.buttonDisabled ? 'Uploading...' : 'Upload' }}
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { formatDateFilter } from '@/utils/lib'

export default {
    name: 'UserBulkInviteModal',
    props: {
        closeModal: Function,
    },

    setup(props, { emit }) {
        const store = useStore()
        const showUserBulkInviteModal = computed(() => store.state?.Admin?.showUserBulkInviteModal)

        const state = reactive({
            buttonDisabled: false,
            imageFile: null,
        })

        const importRef = ref(null)

        const onFileChange = (e) => {
            state.imageFile = e.target.files?.[0]
        }

        const closeModalOnOutsideClick = (e) => {
            if (e.target === importRef.value) {
                props.closeModal()
            }
        }

        const downloadUsersInviteTemplate = () => {
            store.dispatch('Admin/downloadUsersInviteTemplate').then((response) => {
                if (response?.data?.error) {
                    console.error(response.data.error)
                }

                const fileURL = window.URL.createObjectURL(new Blob([response]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
                const fileName = `bulk-users-invite-template.csv`
                fileLink.setAttribute('download', fileName)
                fileLink.setAttribute('target', '_blank')
                document.body.appendChild(fileLink)
                fileLink.click()
                fileLink.remove()
                // state.downloading = false
            })
        }

        const onSubmit = () => {
            state.buttonDisabled = true
            const formData = new FormData()
            formData.append('imageFile', state.imageFile)
            store.dispatch('Admin/uploadUsersInvite', formData).then((resp) => {
                if (resp) {
                    props.closeModal()
                    state.imageFile = null
                    state.buttonDisabled = false

                    store.dispatch('Admin/getBusinessInvites', {
                        filterType: 'adminRange',
                        start: '2020-01-01',
                        end: formatDateFilter(new Date()),
                        limit: route.query.limit || 10,
                        page: route.query.page || 1
                    })
                }
            })
        }

        const updateIncomingState = (incomingState, key) => {
            state[incomingState] = state[key]
        }

        return {
            state,
            emit,
            updateIncomingState,
            importRef,
            closeModalOnOutsideClick,
            downloadUsersInviteTemplate,
            onFileChange,
            showUserBulkInviteModal,
            onSubmit,
        }
    },
}
</script>

<style scoped></style>
